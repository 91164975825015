* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::-webkit-scrollbar-track {
  background: none !important;
}

::-webkit-scrollbar {
  width: 6px !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {}

/* semantic ui changes */
.ui.secondary.pointing.menu .active.item {
  border-bottom: 3px solid;
}

.mp0 {
  margin: 0 !important;
  padding: 0 !important;
}

.m0 {
  margin: 0 !important;
}

.tutorSessionInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  text-align: center;
}

.studentSessionInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 5px;
  text-align: center;
}

.justify-center {
  justify-content: center !important;
}


/* tabs css */
.tabPan {
  margin: 0 !important;
  padding: 16px 4px 0 4px !important;
}

.segment.active.tab {
  padding: 0 !important;
  margin: 0 !important;
}

/* tabs css end */

/* chat tab css */

.chatText {
  display: flex;
  max-width: 80%;
  font-size: 14px;
  margin: 8px 16px;
  position: relative;
}

.recievedChatContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chatText>.chatMessageText {
  font-size: 14px;
  line-height: 21px;
  /* or 150% */

  display: block;
  align-items: center;
  letter-spacing: 0.15px;

  color: #ffffff;
  width: 100%;
  -ms-word-break: break-word;
  word-break: break-word;

  /* Non standard for WebKit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.recievedChatContainer .chatMessageText {
  display: block !important;
  word-break: break-word !important;
  width: 100%;
}

.chatText .chatMessageTime {
  /* position: absolute; */
  float: right;
  font-size: 12px;
  right: 8px;
  bottom: 8px;
  word-break: break-word;
  font-weight: 500;
}

.right {
  background: #009ae0;
  color: #fff;
  align-self: flex-end;
  width: 80%;
  border-radius: 12px;
  padding: 8px;
}

.left {
  background: #f5f5f5;
  color: #000;
  align-self: flex-start;
  width: 80%;
  border-radius: 12px;
  margin-left: 8px;
  padding: 8px;
  width: 100%;
}

.chatInfo {
  display: flex;
  width: 100%;
  justify-content: center;
}

.chatInfo span {
  display: inline-block;
  line-height: 1.7em;
  padding: 8px;
  font-size: 1.4em;
  margin: 8px;
  border-radius: 12px;
}

.chatInfo .success {
  background: #33cc66;
  color: #fff;
  font-size: 1.2em;
  opacity: 0.6;
  line-height: normal;
  width: fit-content;
}

.chatInfo .danger {
  background: #ff4058;
  opacity: 0.6;
  font-size: 1.2em;
  line-height: normal;

  width: fit-content;
  color: #fff;
}

.senderName {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 4px;
}

.userAvatar {
  width: 32px !important;
  height: 32px !important;
  border-radius: 50% !important;
}

/* chat tab css ends */